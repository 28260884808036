import React from 'react';

import AthleteLine from '../../athlete/line';
import BuildImages from '../../builds/image';
import ItemCard from '../../items/card';

import { Link } from 'react-router-dom';

import ReadMore from '../../ui/ReadMore';

const PostCarousel = ({ post }) => {
  const renderImages = (post) => {
    // get images from postCollection _builds and _items and render them

    const images = post.postCollection.map((item, index) => {
      // return first type from key with item that has an id
      const type = Object.keys(item)?.find((key) => item[key]?._id);

      // const type from key
      // const type = Object.keys(item)[0];

      // if type is build
      if (type === '_build') {
        const athlete = item[type]?._athlete && item[type]?._athlete[0];
        // profiles array with _profile and profileType
        const profiles = item[type]?.profiles?._profile && [
          {
            _profile: item[type]?.profiles?._profile,
            profileType: item[type]?.profiles?.profileType,
          },
        ];

        return (
          <div
            className="carousel-item w-9/12 sm:w-4/12 first:ml-4 last:pr-4 sm:first:ml-0 sm:last:pr-0 flex flex-col gap-2"
            key={index}
          >
            <Link to={`/build/${item[type]._id}`} className={``}>
              <BuildImages build={item[type]} noCarousel fullWidth />
            </Link>
            <AthleteLine athlete={athlete} profiles={profiles} />
          </div>
        );
      }

      // if type is item
      if (type === '_item') {
        return (
          <div
            className={`carousel-item w-4/12 sm:w-1/5 first:ml-4 last:pr-4 sm:first:ml-0 sm:last:pr-0`}
            key={index}
          >
            <ItemCard item={item[type]} labelBuilds={true} />
          </div>
        );
      }

      return null;
    });

    return (
      <figure>
        <div className="carousel carousel-center w-full p-0 space-x-2 items-start">
          {images}
        </div>
      </figure>
    );
  };

  const renderTitle = (post) => {
    // if postSubject key is _athlete, render athlete action else render list action
    const subject = post?.postSubject && post?.postSubject[0];
    const type = Object.keys(subject)[0];
    const id = subject[type]?._id;
    const alias = subject[type]?.alias;

    if (type === '_athlete') {
      return (
        <Link
          to={`/${alias}`}
          state={{ backable: true }}
          className="hover:underline underline-offset-4 w-fit"
        >
          <h3 className="text-xl font-medium capitalize">{post.title}</h3>
        </Link>
      );
    }

    return (
      <Link
        to={`/list/${id}`}
        state={{ backable: true }}
        className="hover:underline underline-offset-4 w-fit"
      >
        <h3 className="text-xl font-medium capitalize">{post.title}</h3>
      </Link>
    );
  };

  return (
    <div className="col-span-full">
      <div className="card rounded-none mb-8 gap-2">
        <div className="card-header sm:ml-1 ml-4 text-center  items-center flex flex-col">
          <div className="font-base text-sm text-gray-400">{post.category}</div>
          {renderTitle(post)}
          <div className="max-w-xl py-1">
            <ReadMore>{post.description}</ReadMore>
          </div>
        </div>
        <div className="overflow-hidden overflow-x-scroll w-screen sm:-ml-10">
          {renderImages(post)}
          <div className="card-body pt-2 px-4 items-center sm:px-1"></div>
        </div>
      </div>
    </div>
  );
};

export default PostCarousel;
