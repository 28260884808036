import React from 'react';

import BuildImages from '../../builds/image';
import ItemImages from '../../items/image';

import { Link } from 'react-router-dom';

import ReadMore from '../../ui/ReadMore';

const PostCard = ({ post, mode, postCollectionType }) => {
  if (!post) return null;

  const renderImages = (post) => {
    // get images from postCollection _builds and _items and render them

    if (!post.postCollection) return null;

    const images = post.postCollection.map((item, index) => {
      // const type from key
      const type = Object.keys(item)[0];

      // if type is build
      if (type === '_build') {
        return (
          <div className="carousel-item w-full" key={index}>
            <BuildImages
              key={item[type]}
              build={item[type]}
              noCarousel
              fullWidth
            />
          </div>
        );
      }

      // if type is item
      if (type === '_item') {
        return (
          <div className="carousel-item w-full" key={index}>
            <ItemImages
              item={item[type]}
              noCarousel
              customClass="inset-0 w-96"
            />
          </div>
        );
      }

      return null;
    });

    return (
      <figure key={post._id}>
        <div className="carousel carousel-center w-full p-0 space-x-4">
          {images}
        </div>
      </figure>
    );
  };

  const CardLink = ({ post, children }) => {
    if (!post) return null;

    const type = post.postSubject
      ? Object.keys(post.postSubject[0])[0]
      : post.postSubject[0];

    const alias = post.postSubject[0][type]?.alias;
    const id = post.postSubject[0][type]?._id;
    let link = null;
    if (type === '_athlete') {
      link = `/${alias}`;
    }
    if (type === '_list') {
      link = `/list/${id}`;
    }
    return (
      <Link to={link} state={{ backable: true }}>
        {children}
      </Link>
    );
  };

  const renderButton = () => {
    if (postCollectionType === 'email') {
      const link = `/build/${post.postCollection[0]._build._id}`;
      return (
        <div className="card-footer flex flex-row justify-center pt-4">
          <Link to={link} className="btn btn-brand">
            {post.category ? post.category : 'View Build'}
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="justify-center flex flex-row">
      <div
        className={`card rounded-none px-4 sm:px-1 flex gap-2 ${
          mode === 'edit' ? `w-full max-w-2xl` : `w-11/12 sm:w-9/12`
        }`}
      >
        {postCollectionType === 'email' ? null : (
          <div className="card-header">
            <div className="font-base text-sm text-gray-400">
              {post.category}
            </div>
          </div>
        )}

        <CardLink post={post}>
          <div className={mode === 'edit' ? '' : ''}>{renderImages(post)}</div>
        </CardLink>

        <div className="card-body pt-2 px-0">
          <CardLink post={post}>
            <h3
              className={`capitalize hover:underline underline-offset-4 ${
                postCollectionType === 'email'
                  ? `text-3xl font-medium`
                  : `font-medium text-xl`
              }}`}
            >
              {post.title}
            </h3>
          </CardLink>
          <div>
            {postCollectionType === 'email' ? (
              // div render dangerouslySetInnerHTML
              <div
                className="text-lg font-medium"
                dangerouslySetInnerHTML={{ __html: post.description }}
              />
            ) : (
              <ReadMore>{post.description}</ReadMore>
            )}
            {renderButton()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
