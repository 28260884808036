import './index.css';
import React, { useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import Loader from '../../Loader';

import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/solid';

const BuildImages = ({
  build,
  addBuildLink,
  noCarousel,
  badge,
  badgeClass,
  fullWidth,
}) => {
  const carouselRef = useRef(null);
  const imageRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);

  const onScroll = () => {
    const scrollPosition = carouselRef.current.scrollLeft;
    const slideWidth = carouselRef.current.children[0].offsetWidth;
    const currentSlide = Math.round(scrollPosition / slideWidth);
    setCurrentSlide(currentSlide);
  };

  const { REACT_APP_AWS_S3 } = process.env;

  if (!build?.images) {
    return (
      <Loader
        type="image"
        className="carousel-item w-full relative aspect-square"
      />
    );
  }

  const { name, description, images } = build;

  const lgPadding = fullWidth ? '' : 'lg:mx-20';

  const renderImages =
    images && images?.length > 0 && !noCarousel ? (
      Object.values(images).map((image) => {
        // convert object to array
        const { imageFile } = image;
        const imageUri = REACT_APP_AWS_S3 + '/' + imageFile;

        return (
          <div
            className={`carousel-item w-full relative items-center`}
            key={imageFile}
          >
            {loading && (
              <Loader
                type="image"
                className="carousel-item w-full relative aspect-square"
              />
            )}
            <img
              ref={imageRef}
              key={imageFile}
              src={imageUri}
              alt={`${name} ${description}`}
              className="object-scale-down w-full max-h-[85dvh] animate-fade-in touch-pinch-zoom touch-manipulation"
              style={{
                display: loading ? 'none' : 'block',
                width: '100%',
              }}
              onLoad={() => setLoading(false)}
            />
          </div>
        );
      })
    ) : images?.length > 0 ? (
      <div
        className={`carousel-item w-full items-center`}
        key={images[0].imageFile}
      >
        {loading && (
          <Loader
            type="image"
            className="carousel-item w-full aspect-square "
          />
        )}
        <img
          ref={imageRef}
          key={images[0].imageFile}
          src={REACT_APP_AWS_S3 + '/' + images[0].imageFile}
          alt={`${name} ${description}`}
          className="object-scale-down w-full max-h-[85dvh] animate-fade-in touch-manipulation"
          style={{
            display: loading ? 'none' : 'block',
            width: '100%',
          }}
          onLoad={() => setLoading(false)}
        />
      </div>
    ) : (
      <div className="carousel-item w-full relative items-center aspect-square">
        <div className="w-full h-full flex flex-col items-center justify-center gap-0">
          <div className="text-center text-gray-400">{name}</div>
          <div className="text-center text-gray-400">Draft Build</div>
        </div>
      </div>
    );

  const renderDots =
    !noCarousel &&
    images?.length &&
    Object.values(images).map((image, index) => {
      // conver object to array
      const { imageFile } = image;
      // on click, scroll to image
      const onClick = () => {
        const slideWidth = carouselRef.current.children[0].offsetWidth;
        carouselRef.current.scrollLeft = index * slideWidth;
      };
      return (
        <div
          className={`dot ${
            currentSlide === index ? 'active' : 'cursor-pointer'
          }`}
          key={imageFile}
          onClick={onClick}
        ></div>
      );
    });

  const onArrowClick = (direction) => {
    const slideWidth = carouselRef.current.children[0].offsetWidth;
    if (direction === 'left') {
      carouselRef.current.scrollLeft -= slideWidth;
    } else {
      carouselRef.current.scrollLeft += slideWidth;
    }
  };

  // left and right arrows
  const renderLeftArrow = !noCarousel && (
    <div>
      {currentSlide !== 0 && (
        <div onClick={() => onArrowClick('left')} className="">
          <ArrowLeftCircleIcon className="h-7 w-7 text-gray-300 opacity-70 cursor-pointer" />
        </div>
      )}
    </div>
  );
  const renderRightArrow = !noCarousel && (
    <div>
      {currentSlide !== images.length - 1 && (
        <div onClick={() => onArrowClick('right')} className="">
          <ArrowRightCircleIcon className="h-7 w-7 text-gray-300 opacity-70 cursor-pointer" />
        </div>
      )}
    </div>
  );

  // create a link to the build component
  const buildLink = `/build/${build._id}`;

  // show left and right arrows on hover
  const carouselBlock = (
    <div
      // className="carousel w-full"
      className={`carousel w-full ${!addBuildLink && lgPadding}`}
      ref={carouselRef}
      onScroll={onScroll}
    >
      {renderImages}
    </div>
  );

  return (
    <>
      {addBuildLink ? (
        <Link to={buildLink} state={{ backable: true }}>
          {badge && (
            <div className="absolute top-0 right-2 mt-2 z-20">
              <div
                className={`badge badge-ghost font-extralight text-xs bg-base-100 ${badgeClass}`}
              >
                {badge}
              </div>
            </div>
          )}
          {carouselBlock}
          {images?.length > 1 && <div className="dots">{renderDots}</div>}
        </Link>
      ) : (
        <div className="flex flex-1 relative">
          {carouselBlock}
          <div className="absolute flex justify-between transform -translate-y-1/2 left-2 right-2 top-1/2">
            {images?.length > 1 && renderLeftArrow}
            {images?.length > 1 && renderRightArrow}
          </div>
          {images?.length > 1 && (
            <div className="absolute flex justify-between transform translate-y-0 left-5 right-5 bottom-2">
              <div className="dots">{renderDots}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BuildImages;
